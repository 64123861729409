.search-refinement__title {
  @include primaryFont(500);
  font-size: 1.8rem;
  margin: 0;
  color: var(--main-color);

  .search-refinement__title-content {
    display: flex;
    gap: 0.8rem;
    align-items: center;

    .icon__badge {
      width: 1.2rem;
      height: 1.2rem;
      font-size: 1rem;
    }
  }
}
.search-refinement__group {
  &:first-child {
    .search-refinement__title {
      border-top: 0px;
    }
  }
  .search-refinement__title {
    @include primaryFont(500);
    font-size: 1.4rem;
    margin: 0;
    color: var(--main-color);
    padding-top: 2rem;
    border-top: 1px solid var(--light-grey);
    margin: 0rem 2rem 2rem 2rem;

    .counter-containter {
      display: flex; 
      flex-direction: row; 
      gap: 0.8rem; 
      align-items: center;
    }
  }
}
.applied-filters-indicator {
  background-color: var(--main-color); 
  border-radius: 100%;
  padding: 0.1rem 0.5rem; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  color: var(--white);
}
.search-refinement__values {
  padding: 0rem 2rem;
}
.search-refinement__item {
  display: flex;
  .form-element__checkbox,
  .form-element__radio {
    &.search-refinement__checkbox,
    &.search-refinement__radio {
      border-color: var(--black);
      
      &:checked {
        border-color: var(--main-color);
        ~ .form-element__label_checkbox,
        ~ .form-element__label_radio {
          color: var(--main-color);
        }
      }
      
      &:hover {
        border-color: var(--main-color);
        ~ .form-element__label_checkbox,
        ~ .form-element__label_radio{
          color: var(--main-color);
        }
      }
    }
  }
  &.form-element {
    display: flex;
    margin-bottom: 2rem;
    &:not(.search-refinement__item--last) {
      margin-bottom: 3.2rem;
    }
    .swatch-filters {
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      margin: 0 0.2rem;
      margin-right: 0.4rem;
      box-shadow: 0.1px 0.1px 1px 1px #999;
    }
  }
  &.checkbox__container,
  &.radio__container {
    .form-element__label_checkbox,
    .form-element__label_radio {
      padding-left: 0;
      color: var(--black);
    }

    .form-element__label_radio {
      padding-left: 0.6rem;
    }
  }
  &.filter-disabled{
    cursor: not-allowed;
    label,input{
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.side-panel {
  .action-bar {
    &.action-bar__filters {
      .icon__badge {
        width: 1.6rem;
        height: 1.6rem;

        &.icon__badge__close {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}

  /* Stili per il componente switcher */
.switch-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    margin: 0rem 2rem 2rem 2rem;
    border-top: 0.1rem solid var(--light-grey);

    &.filter-disabled {
      cursor: not-allowed;
      span,label,input{
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    .switch-label {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--black);
  }

  .form-element__label_checkbox.switch {
    position: relative;
    display: inline-block;
    width: 3.2rem;
    height: 1.6rem;

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--white);
      border-radius: 1rem;
      transition: .4s;
      border: 0.1rem solid var(--black);
  
      &::before {
        position: absolute;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        left: 0.4rem;
        bottom: calc(50% - 0.6rem);
        background-color: black;
        border-radius: 50%;
        transition: .4s;
      }
    }

    input.search-refinement__checkbox {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked {
        & + .slider {
          background-color: var(--main-color);
          border: 0.1rem solid var(--main-color);

          &::before {
            transform: translateX(1.2rem);
            background-color: var(--white);
          }
        }
      }
    }
  }
}
