.main-header {
  position: relative;
  .header_navigation {
    position: absolute;
  }

  &.sticky-header {
    transition: background-color ease-in-out .2s, height .5s linear 0.3s;
    background-color: white;
    transform: translateY(-100%);
    position: fixed;
    top: 0;
    z-index: 200;
    left: 0;
    right: 0;
    height: 6.5rem;

    .header__logo {
      position: relative;
      max-width: 25rem;
    }
    &.transparent {
      background-color: transparent;
    }
    .side-panel--header-panel {
      top: 100%;
      .header-panel {
        margin-top: 0;
        height: 100%;
      }
    }
  }

  &.animate {
    transition: transform ease-in 0.4s;
  }
}


// body[data-component='PlpAnimationComponent'] {
//   &.scroll-down {
//     .search-filters  {
//       border: none;
//       border-bottom: 1px solid var(--main-color);
//     }
//   }
// }

.scroll-up {
  .sticky-header {
    transition: transform ease-in .4s, background-color ease-in .2s, height .5s ease;

    &.is-open,
    &.is-open-mobile {
      height: 100%;

      &.add-to-cart-popup {
        height: auto;
      }
    }
  }
  .main-header.sticky-header {
    transform: translateY(0);
    .side-panel--header-panel {
      top: 0;
      .header-panel {
        @media (min-width: 992px) {
          margin-top: 4rem;
        }
        height: calc(100% - 4rem);
      }
    }
  }
}
