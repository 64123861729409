.filter-bar {
  display: flex;
  min-width: 31rem;
  max-width: 63.5rem;
}

.module-static-plp--editorial {
  .swiper-button-prev {
    position: absolute;
    display: block;
    left: -3rem;
    top: calc(50% - 1.2rem);
    right: unset;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.hidden {
      display: none;
    }
  }
  
  .swiper-button-next {
    position: absolute;
    display: block;
    right: -3rem;
    top: calc(50% - 1.2rem);
    left: unset;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.hidden {
      display: none;
    }
  }
  
  .scrollbar-filters {
    display: none;
  }
}