.giftcard__custom-input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  //   border: 1px solid $black;
  -moz-appearance: textfield;
  &.is-invalid {
    // border: 1px solid $red;
  }
  &.is-valid {
    // border: 1px solid $malachite;
  }
  &.giftcard__custom-input--filled {
    .giftcard__custom-value--3 & {
      padding-left: 3rem;
    }
    .giftcard__custom-value--2 & {
      padding-left: 2rem;
    }
    .giftcard__custom-value--1 & {
      padding-left: 1.5rem;
    }
  }
}
.giftcard__message {
  .side-panel__inner {
    .action-bar {
      border: 0;
      display: block;
      position: absolute;
      .action-bar__button {
        position: absolute;
        right: 0;
        height: 4rem;
        border-left: 1px solid var(--main-color);
        border-bottom: 1px solid var(--main-color);
      }
    }
    .giftcard-message__content {
      height: 100%;
      .product-line-item-details {
        .item-image{
          width: 10.2rem;
          height: 15.3rem;
          border: 1px solid var(--main-color);
          .product-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .item-attributes {
          color: var(--main-color);
          padding-left: 1.2rem;
          .line-item-name {
            margin-bottom: 1.4rem;
            color: var(--black);
            .gift-card-item-link {
              text-decoration-line: none;
            }
          }
          .line-item-attributes {
            margin-bottom: 0.6rem;

            .line-item-attributes-label,
            .line-item-attributes-value {
              color: var(--black);
              font-size: 1.2rem;
            }
          }
        }
      }
      .giftcard-message__form, 
      .product-line-item {
        padding: 2rem;
      }
      .giftcard-message__form {
        padding-top: 0;
        .form-element {
          &:last-of-type {
            margin-bottom: 2.4rem;
          }
          .form-element__input {
            border-bottom: 1px solid var(--black);
            color: var(--black);
          }
          .form-element__label {
            color: var(--black);
          }
        }
      }
      .giftcard-message__form-description,
      .giftcard-message_title {
        font-size: 1.8rem;
      }
      .giftcard-message__form-description {
        margin-bottom: 2.8rem;
        margin-top: 0;
        color: var(--black);
        font-size: 1.4rem;
      }
      .giftcard-message_title {
        @include primaryFont($weight: 500);
      }
    }
  }
}
.checkout-container-box .checkout-section fieldset {
  &.giftcard__fieldset {
    margin-top: 2rem;
    padding: 3rem 2rem;
    border: 1px solid var(--light-grey);
    .info-popup-container .checkout-info-popup {
      top: 0.7rem;
      right: 2rem;
      .content {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        p {
          line-height: 1.5;
          margin-top: 0;
          font-weight: 500;
        }
      }
    }
    .form-element {
      margin-bottom: 1rem;
    }
  }
}
.giftcard__balance{
  p {
    background-color: var(--green-validation);
    color: var(--white);
    padding: 0 0.5rem;
    font-size: 1.4rem;
  }
}
.giftcard-form-container {
  .remove-giftcard {
    font-size: 1.4rem;
  }
}
.giftcard__errors {
  p {
    background-color: var(--main-color);
    color: var(--white);
    padding: 0 0.5rem;
    font-size: 1.4rem;
  }
}
.giftcard__error {
  .side-panel__inner {
    padding: 2rem;
  }
}
.giftcard__balance {
  margin-bottom: 2rem;
}
.giftcard-modal {
  .side-panel__inner {
    overflow-y: auto;
  }
  .giftcard-modal__container {
    padding: 2rem;
  }
  .giftcard-modal__title {
    color: var(--main-color);
  }
}

.variation-attribute {
  &.giftcardAmount {
    .form-element__label, .form-element__input {
      color: var(--black);
    }
    .form-element__input {
      border-bottom: 0.1rem solid var(--black);
    }
  }
}

.product-detail {
  .attribute-values {

    &.giftcardType {
      .attribute-value {
        &.js_attribute-giftcardType {
          span {
            color: var(--main-color);
          }
        }
      }
    }
  }
}

.product-tile--add-to-bag, .product-content__body {
  .attribute-values {
    &.giftcardType {
      .attribute-value {
        &.js_attribute-giftcardType {
          border: 0rem;
          color: var(--black);
          padding-left: 0;
          padding-right: 1.96rem;

          span {
            text-transform: lowercase;
            position: relative;
            padding-left: 2.5rem;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              background-color: var(--white);
              border: 0.1rem solid var(--main-color);
              border-radius: 50%;
              width: 1.6rem;
              height: 1.6rem;
            }
          }

          span::first-letter {
            text-transform: capitalize;
          }

          &.selected {
            background-color: transparent;
            border: 0rem;
            color: var(--black);
            box-shadow: unset;

            span {
              &::after {
                content: '';
                width: 1rem;
                height: 1rem;
                position: absolute;
                top: 50%;
                left: 0.3rem;
                transform: translateY(-50%);
                background-color: var(--main-color);
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}