:root {
  --header-height: 0;
  --logo-header: calc(25vw / 1.8);
}
.search-banner {
  width: 100%;
  height: auto;
  min-height: calc(var(--logo-header) + 6rem);
  display: flex;
  flex-direction: column;
  

  .breadcrumb-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: auto;
  }

  .breadcrumbs {
    padding: 2rem;

    &.spaced-breadcrumb {
      margin-top: 0;
    } 
    &.no-min-height {
      min-height: 12rem;
    }
  }
}

.search-banner__content {
  // padding: 60px 0 49px;
  min-height: calc(var(--logo-header) - 6rem);
  padding: 0;
  width: 100%;

  .module-static-plp {
    margin: 6rem auto 0;
  }
  
  &.search-no-hidden-banner {
    margin: 6rem auto 0;

    .module-static-plp {
      margin: 0 auto;
    }
  }
  
  .search-banner__title {
    text-align: left;
    margin: 0 auto;
    margin-left: 0;
    padding: 0.4rem;
    width: fit-content;
  }
  .search-banner__description {
    width: 100%;
    text-align: left;
    margin: 0 auto;
    padding: 1.2rem;
  }
  .search-banner-filters {
    max-width: 62rem;
    margin: 0 auto;
    padding: 0;
  }
}

.search-banner__container {
  width: 43%;
  &-spaced {
    margin-bottom: 8rem;
  }
}

.hide-box {
  .search-banner__content {
    .search-banner__title {
      text-align: center;
      margin: 0 auto;
      border: 0;
    }
    .search-banner__description {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      border: 0;
    }
  }
}
