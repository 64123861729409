.filter-bar {
  width: 100%;
  display: none;
  align-items: center;
  gap: 0.8rem;
  margin-left: 0.8rem;

  .filter-container {
    overflow: hidden;
    position: relative;
    display: flex;
  }

  .filter-track {
    display: flex;
    scroll-behavior: smooth;
    transition: transform 0.3s ease;
  }

  .filter-navigation {
    cursor: pointer;
    display: none;
    
    &.visible { 
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  svg[class^='icon'] {
    fill: var(--main-color);
    width: 1.8rem;
    height: 1.8rem;
  }

  .filter-list__item {
    &.button.tag {
      padding: 0 0.8rem;
      font-size: 1.2rem;
      margin: 0 0.4rem;
      background-color: var(--main-color);
      color: var(--white);

      &:first-child {
        margin-left: 0;
      }
  
      &:last-child {
        margin-right: 0;
      }
    }

    .button__icon {
      margin-right: 0.4rem;

      svg[class^='icon'] {
        fill: var(--white);
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .clear-filter {
    margin-left: 0.8rem;
    font-size: 1.2rem;
  }
}

.dropdown_refinments_form {
  display: flex;
  z-index: 100001;

  .dropdown-filter {
    position: relative;
  
    .dropdown-toggle {
      height: 3.2rem;
      padding: 0.9rem 0.8rem;
      display: flex;
      gap: 0.2rem;
      flex-direction: row;
      font-size: 1.2rem;
  
      svg {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  
    &.dropdown-open {
      .dropdown-menu {
        display: block;
        max-height: 26.6rem;
        width: 17.9rem;
        ul {
          display: flex;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
  
          /* width */
          &::-webkit-scrollbar {
            width: 0.2rem;
          }
  
          /* Track */
          &::-webkit-scrollbar-track {
            background: var(--white);
          }
  
          /* Handle */
          &::-webkit-scrollbar-thumb {
            width: 0.2rem;
            background: var(--main-color);
          }
        }
  
      }
      .dropdown-toggle {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  
    .dropdown-menu {
      display: none;
      position: absolute;
      background-color: var(--white);
      border: 1px solid var(--main-color);
      padding: 1.6rem;
      padding-right: 0.6rem;
      margin: 0;
      margin-top: 1rem;
      top: 3rem;

      &.sort-dropdown {
        right: 0;
      }
      
      ul {
        flex-direction: column;
        gap: 2.4rem;
        list-style-type: none;
        padding: 0;
        padding-right: 1rem;
        margin: 0;
      }
  
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
  
        .checkbox__container {
          justify-content: flex-start;
          margin: 0;
          .form-element__checkbox {
            border: 1px solid var(--black);
            &:checked {
              border: 1px solid var(--main-color);
              &~ label {
                color: var(--main-color);
              }
            }
            &~ label {
              color: var(--black);
            }
    
            &:hover {
              border: 1px solid var(--main-color);
              cursor: pointer;
    
              &~ label {
                color: var(--main-color);
                cursor: pointer;
              }
            }
          }
        }

        .radio__container {
          margin: 0;
          justify-content: flex-start;

          label {
            color: var(--black)
          }

          .form-element__radio {
            border: 1px solid var(--black);
            &::after {
              background: var(--black);
            }
          }
        }
      }
  
    }
  }  
}

.outside-toggler {
  width: 100vw; 
  height: 100vh; 
  position: fixed; 
  top: 0; 
  left: 0; 
  z-index: 100001;
}
.module-static-plp--editorial {
  .carousel-container {
    position: relative;
    &.hidden {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }

    .editorial-container {
      .pd-c-container {
        border: 0;
        overflow-x: clip;
        overflow-y: visible;
      }
      .swiper-slide {
        border: 0;
        margin-top: 0;
        img {
          border: 1px solid var(--main-color);
          object-fit: cover;
          // height: calc(100% - 2.6rem);
          // width: 100%;
          // box-sizing: border-box;
          // min-height: 16.75rem;
          // aspect-ratio: 1; //actually not working but is the correct way to make div square
          float: left;
          width: 100%;
          box-sizing: border-box;

          &::before {
            content: '';
            display: block;
            padding-top: 100%;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          text-decoration: none;
        }
  
        h1 {
          padding: 0.4rem 1rem 0 1rem;
          font-size: 2rem;
          font-family: franklin-gothic-compressed, sans-serif;
          height: auto;
          border-bottom: 0;
        }
      }
    }
  }

  .swiper-button-prev {
    display: none;
  }
  
  .swiper-button-next {
    display: none;
  }
  
  .scrollbar-filters {
    background: var(--light-grey);
    border-radius: 2px;
    margin: 0.6rem 0;
    width: 100%;

    .swiper-scrollbar-drag {
      background: var(--main-color);
      height: 0.2rem;
    }
  }
}
