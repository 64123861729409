@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-plp__hod-plp-card {
  position: relative;

  @include media-breakpoint-up(lg) {
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-up(lg) {
      max-width: 61%;
    }
  }

  a {
    text-decoration: none;
  }

  &__heading {
    img {
      display: block;
      width: 24rem;
    }
  }

  &__content-text {
    padding: 2rem;
    border: 1px solid var(--main-color);
    border-bottom: none;

    p {
      font-size: 1.8rem;
      margin: 0;
    }
  }

  &__text-offer {
    color: var(--main-color);
    font-size: 1.4rem;
    text-align: left;
    margin-top: 1.2rem;

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.2rem;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__content-img {
    width: 100%;
    border: 1px solid var(--main-color);
    border-top: none;
    height: 0;
    padding-bottom: 73%;
    position: relative;

    @include media-breakpoint-up(lg) {
      padding-bottom: 55%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__content-buttons {
    position: absolute;
    left: 2rem;
    bottom: 0;
    width: calc(100% - 4rem);
    z-index: 2;

    .hod-button {
      margin-bottom: 2rem;
      width: 100%;
    }
  }
}
