.pagination__holder {
  margin: 6rem 0;
}
.border-red-plp{
  .pagination__holder:before {
    height: calc(100% + 12rem);
    top: -6rem;
  }
}
.product-grid {
  .pagination__holder {
    grid-column: span 4;
  }
}
