#maincontent {
  --old-main-color: var(--main-color);
  --old-white: var(--white);
}
.search-banner {
  // min-height: 12rem;
  .breadcrumbs {
    padding-top: 36px;
    &.spaced-breadcrumb {
      padding-top: var(--header-height);
      margin-top: 2rem;
      // margin-top: 49px;
    } 
  }
  &.no-min-height {
    min-height: 0;
  }
}

.search-banner.invert {
  --main-color: var(--old-white);
  --white: var(--old-main-color);
  --border-color: var(--old-white);
  --text-color: var(--old-white);

  background-color: var(--old-main-color);
}

.search-banner__container {
  width: 100%;
  margin: 0 auto;
  .search-banner__title {
    font-size: 3.4rem;
    text-align: left;
    margin: 0 2rem;
    border: 1px solid var(--main-color);
    width: fit-content;
    padding: 0 0.8rem;
  
    & ~ .search-banner__description {
      margin-top: 0;
      padding: 0 0.8rem;
    }
  }
  .search-banner__description {
    text-align: left;
    margin: 0 2rem 2rem 2rem;
    border: 1px solid var(--main-color);
  
    &-spaced {
      margin-bottom: 2rem;
    }
  }
}

.hide-box {
  .search-banner__container {
    &-spaced {
      margin-bottom: 2rem;
    }
    .search-banner__title {
      font-size: 3.4rem;
      text-align: left;
      margin: 0 2rem 2rem 2rem;
      border: 0;
    
      & ~ .search-banner__description {
        margin-top: 0;
      }
    }
    .search-banner__description {
      text-align: left;
      margin: 0 2rem;
      border: 0;
    }
  }
}

.search-banner-filters {
  padding: 2rem;
  &__label-box {
    display: inline-block;
    margin-bottom: -0.1rem;
    padding: 0 0.8rem;
    border: 1px solid var(--main-color);
  }
  &__title {
    font-size: 3.6rem;
    padding: .2rem 0;
    margin: 0;
    .icon-search {
      width: 2rem;
      height: 2rem;
      margin-left: 0.5rem;
    }
  }
  &__content-box {
    padding: 1.2rem;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    line-height: 2rem;
    letter-spacing: 0.2;
    .search__container {
      margin-bottom: 0;
    }
    .search-category__list {
      margin: 1.2rem 0 0;
      row-gap: 0.8rem;
      .search-category__item {
        margin-bottom: 0;
      }
      .form-element {
        margin-bottom: 0;
      }
    }
    .latest-search,
    .popular-search-terms,
    .search-terms {
      display: none;
    }
  }
}
.search-banner__content {
  .visualfilters-title{
    .headeing-title-plp{
      display: flex;
      justify-content: center;
      margin-bottom: 5rem;
      margin-top: 0;
      font-size: 34px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.94;
      @media (max-width: 991px) {
        margin-bottom: 2rem;
        padding-left: 2rem;
        justify-content: flex-start;
        font-size: 30px;
        line-height: 0.93;
      }
    }
  }
  &.no-padding-top {
    padding-top: 0;
  }
}
.search-banner {
  .breadcrumbs {
    padding-top: 36px;
  }
  @media (max-width: 991px) {
    .breadcrumbs{
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 0.17px;
      padding-top: 2rem;
    }
  } 
}