.border-red-plp{
  .pagination__holder:before {
    content: "";
    width: 2px;
    height: calc(100% + 4rem);
    position: absolute;
    left: -1px;
    top: -2rem;
    background: #fff;
  }
}
.pagination__holder {
  position: relative;
  text-align: center;
  margin: 2rem 0;
}
.pagination__counter {
  color: var(--main-color);
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.product-grid {
  .pagination__holder {
    grid-column: span 2;
  }
}
