@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-plp__hod-plp-strip {
  background: var(--main-color);
  position: relative;
  width: 100%;

  &__wrapper {
    padding: 3.2rem 7rem;
  }

  p {
    display: block;
    color: var(--white);
    text-align: center;
    margin: 0;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
