@import './typography';
@import './utils/mixins';
@import 'components/search/header';
@import 'components/search/filter-bar';

// @import 'components/search/search-filters';
@import 'components/search/search-refinement';
@import 'components/search/search-banner';
@import 'components/pagination/pagination';

@media (min-width: 992px) {
  @import 'components/search/header.lg';
  @import 'components/search/search-banner.lg';
  @import 'components/search/search-filters.lg';
}

@media (min-width: 1024px) {
  @import 'components/search/filter-bar.lg';
  @import 'components/pagination/pagination.lg';
}

@media (min-width: 1440px) {
  @import 'components/search/filter-bar.xl';
}

@import 'loyalty/modules/plp/hod-plp-card';
@import 'loyalty/modules/plp/hod-plp-strip';
@import 'components/giftcard/giftcard';
