.main-header {
  &.sticky-header {
    height: 8rem;

    .header__logo {
      max-width: 14.5rem;
    }

    &.is-open {
      transform: translateY(0);
    }
  }
}

.scroll-up {
  .sticky-header {
      &.is-open {
        height: auto;
      }
      &.is-open-mobile {
        height: 100%;
    }
  }
}

.main-header.is-open {
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
  top: var(--nav-position);
}
