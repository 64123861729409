.filters-active {
  width: 40%;
}

.search-filters { 
  border-width: 1px 0;
  padding: 1.6rem 2.4rem;
}
.scroll-up {
  .search-filters {
    &.fixed {
      width: 100%;
      position: fixed;
      & ~ .product-grid {
        padding-top: 74px;
      }
    }
    &.stuck {
      margin-top: -1px;
      transform: translateY(var(--mainHeaderHeight));
    }
  }
}

.search-filters__filters {
  .filters-on-navbar {
    display: flex;
  }
}
.action-bar__filters {
  .title {
    font-size: 1.8rem;
  }
}
